<template>
    <div>
      <v-toolbar flat color="#263238" dark fixed>
        <v-toolbar-title>RUNT</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        
        
           
            

         
        </v-toolbar>
        <!-- <v-dialog v-model="dialog" max-width="800px"> -->
          
          <v-card>
            <v-card-title>
              <span class="headline">{{formTitle}}</span>
            </v-card-title>
  
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>

                  


  
  

                  
                  


                  <v-flex xs12 sm6 md4>

                  <v-file-input
      v-model="files"
      placeholder="Cargar Lista"
      label="File input"
      
      prepend-icon="mdi-paperclip"
    >
      <!-- <template v-slot:selection="{ fileNames }">
        <template v-for="fileName in fileNames" >
          <v-chip
          :key="fileName"
            size="small"
            label
            color="primary"
            class="me-2"
          >
            {{ fileName }}
          </v-chip>
        </template>
      </template> -->
    </v-file-input>
  </v-flex>

                              
                </v-layout>
              </v-container>
            </v-card-text>
  
            <v-card-actions>
                <v-spacer></v-spacer>                
                
                <v-btn style="color:rgb(247, 237, 237)" @click="cargarlista" color="#F57C00" dark class="mb-2">Cargar Lista</v-btn>
              
              </v-card-actions>
          </v-card>
        <!-- </v-dialog> -->
  
    </div>
  </template>
  <script>
  import axios from 'axios';
  //import jsPDF from "jspdf";

  export default {
    data: () => ({
      dialog: false,
      files:[],
      file:'',
     
   
   
      respuesta:'',
      tipo_reporte:'',
      condition1: true,
      condition2: false,
      condition3: true,
      condition4:false,
      condition5:true,
      parametro_nombre:"",
      title1:''
    
    }),
created(){

},
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'CARGUE RUNT' : 'CARGUE RUNT'
      }
      
    },
    
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },  

    methods: {    
     
    cargarlista(){
      if (this.files=='') {
        this.$swal.fire(
          "Intentar de nuevo",
          "No Adjunto el archivo",
          "info"
        );
      } else {
        // this.$swal.fire({
        //   title: "Cargando...",
        //   html: "Por Favor Espere...",
        //   allowEscapeKey: false,
        //   allowOutsideClick: false,
        //   willOpen: () => {
        //     this.$swal.showLoading();
            
        //   },
        // });
        // this.$loading.show({ background: 'black', color: '#00FF00' });
       
         console.log(this.files);
        let formData = new FormData();
        
        formData.append("files", this.files);  
       

  
        const datospermitidos = {"Content-Type": "multipart/form-data"};
        axios.post("https://citav.ibingcode.com/public/cargarlista",formData,datospermitidos)
          .then((respuesta) => {
            console.log(respuesta);
            
            var msg = respuesta.data;
          
            if(msg.CODIGO == 0){
                   this.$swal.fire({
                     title:"exito",
                     text:msg.MENSAJE,
                     allowOutsideClick: false,
                showConfirmButton:false,
                     icon:"success"})
                 } else {
                   this.$swal.fire({
                     title:"La informacion de este archivo ya se encuentra registrada",
                     text:msg.MENSAJE,
                     icon:"error"})
                 }
          });
      }
    },
    
    }
  }
</script>
<style scoped>

.swal2-styled.swal2-confirm {
    border: 0;
    border-radius: .25em;
    background: initial;
    background-color: #d69430;
    color: #ffffff;
    font-size: 1.0625em;
}
.swal2-styled.swal2-confirm  {
    border-left-color: #5D5D5D !important;
    border-right-color: #5D5D5D !important;
}

</style>